<template>
  <nav>
    <router-link to="/clients">Clients</router-link> | 
    <a @click.prevent="logout" href="">Logout</a>
  </nav>
  <router-view/>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store.state.authtoken = ""
      this.$router.push('/')
    }
  },
  mounted() {
    console.log(process.env.VUE_APP_ROOT_API)
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
