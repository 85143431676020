<template>
    <!-- <p>{{ process.env.VUE_APP_ROOT_API}}</p> -->
  <form @submit.prevent="handleSubmit">
      <label>Username:</label>
      <input v-model="username" type="text" required>

      <label>Password:</label>
      <input v-model="password" type="password" required>

      <div class="submit">
          <button>Log In</button>
      </div>


  </form>
</template>

<script>
export default {
    data() {
        return {
            username: '',
            password: '',
            role: 'designer',
            terms: false,
            tempSkill: '',
            skills: [],
            tokenResponse: ''
        }
    },
    methods: {
        addSkill(e) {
            if (e.key === ',' && this.tempSkill) {
                if (!this.skills.includes(this.tempSkill)){
                    this.skills.push(this.tempSkill);
                
                }
                this.tempSkill = '';
            }
        },
        async handleSubmit() {
            const res = await fetch(
                process.env.VUE_APP_ROOT_API +
                "/login", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        username: this.username, 
                        password: this.password
                        })
                }
            )

            if (!res.ok) {
                alert("invalid username or password");
                this.$store.commit('setToken', '');
                this.username = '';
                this.password = '';
                this.$router.push('/');
            } else { 
                res.json().then(data => {
                this.tokenResponse = data;
                this.$store.commit('setToken', data.access_token)
                this.$router.push('/clients');
            });}

           
        }
    }
}
</script>

<style>
  form {
    max-width: 420px;
    margin: 30px auto;
    background: white;
    text-align: left;
    padding: 40px;
    border-radius: 10px;
  }
  label {
    color: #aaa;
    display: inline-block;
    margin: 25px 0 15px;
    font-size: 0.6em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
  }
  input, select {
    display: block;
    padding: 10px 6px;
    width: 100%;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid #ddd;
    color: #555;
  }
  input[type="checkbox"] {
      display: inline-block;
      width: 16px;
      margin: 0 10px 0 0;
      position: relative;
      top: 2px;
  }
  button {
      background: blue;
      border: 0;
      padding: 10px 20px;
      margin-top: 20px;
      color: white;
  }
  .submit {
      text-align: center;
  }
</style>