import { createApp } from 'vue'
import { createStore } from 'vuex'
import App from './App.vue'
import router from './router'

const store = createStore({
    state() {
        return {
            count: 0,
            authtoken: ""
        }
    },
    mutations: {
        increment (state) {
            state.count++
        },
        setToken(state, token) {
            state.authtoken = token
        }
    }
})

createApp(App).use(router).use(store).mount('#app')