<template>
  <form @submit.prevent="handleAdd">
    
          <label>Name</label>
          <input type="text" v-model="nameToAdd" />
          <div class="submit">
            <button>Add</button>
          </div>
  </form>

  <table>
    <tr>
      <th>id</th>
      <th>Name</th>
    </tr>

    <tr v-for="client in clients" :key="client.id">
      <td>{{ client.id }}</td>
      <td>{{ client.name }}</td>
      <td><button @click="handleDownload(client.id)">Download</button></td>
      <td><button @click="handleDelete(client.id)">Delete</button></td>
    </tr>
  </table>
</template>

<script>
export default {
  data() {
    return {
      clients: [],
      nameToAdd: "",
    };
  },
  methods: {
    handleAdd() {
      fetch(process.env.VUE_APP_ROOT_API + "/clients", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.$store.state.authtoken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: this.nameToAdd,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          this.nameToAdd = "";
          this.handleUpdate();
        });
    },
    handleUpdate() {
      fetch(process.env.VUE_APP_ROOT_API + "/clients", {
        headers: {
          Authorization: `Bearer ${this.$store.state.authtoken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.clients = data;
        })
        .catch((err) => console.log(err.message));
    },
    handleDelete(clientId) {
      fetch(process.env.VUE_APP_ROOT_API + `/clients/${clientId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${this.$store.state.authtoken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          this.handleUpdate();
        })
        .catch((err) => console.log(err.message));
    },
    handleDownload(clientId) {
      fetch(process.env.VUE_APP_ROOT_API + `/clients/${clientId}`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.authtoken}`,
        },
      })
        .then((response) => response.blob())
        .then((data) => {
          console.log(data);
          const link = document.createElement('a');
          link.href = URL.createObjectURL(data);
          link.download = `${this.clients.filter((client) => client.id == clientId)[0].name}.ovpn`;
          link.click();
          link.remove();
        })
        .catch((err) => console.log(err.message));
    }
  },
  mounted() {
    if (!this.$store.state.authtoken) {
      this.$router.push("/")
      alert("You must first log in")
    } else {
      this.handleUpdate();
    }
  },
};
</script>

<style>
table {
  display: inline-block;
  /* border: 1px solid; */
    border-collapse: collapse;

}
td, th {
  min-width: 100px;
  padding: 0 20px;
  margin: 10px;
  border: 1px solid;
}
</style>