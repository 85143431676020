<template>
  <h1>Client List</h1>
    <client-list/>
</template>

<script>
import ClientList from "@/components/ClientList.vue"

export default {
  components: { ClientList },

}
</script>

<style>

</style>